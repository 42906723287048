/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
// @import "jsoneditor/dist/jsoneditor.css";

// @import "~@formio/js/dist/formio.full.css";
@import "~bootstrap/dist/css/bootstrap.css";

$fa-font-path: '~font-awesome/fonts';
@import "font-awesome/scss/font-awesome.scss";